/* Lighter text for secondary elements */
.text-secondary-light {
    color: #374f8d; /* Lighter shade for contrast */
}

/* Sparkle effect for the Forward icon */
.group:hover .sparkle {
    background: linear-gradient(
            to right,
            #ff0000,
            #ffa500,
            #ffff00,
            #008000,
            #0000ff,
            #4b0082,
            #ee82ee
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: sparkle 1.5s linear infinite;
}

@keyframes sparkle {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 100%;
    }
}
