/* Lighter text for secondary elements */
.text-secondary-light {
    color: #374f8d; /* Lighter than #182C5C */
}

/* Glow effect for stars */
.glowing-star {
    animation: glow 1.5s infinite alternate;
}

@keyframes glow {
    from {
        fill: yellow;
        filter: drop-shadow(0 0 4px yellow);
    }
    to {
        fill: #ffff99;
        filter: drop-shadow(0 0 8px yellow);
    }
}

/* Sparkle effect on hover */
.group:hover .sparkle {
    background: linear-gradient(
            to right,
            #ff0000,
            #ffa500,
            #ffff00,
            #008000,
            #0000ff,
            #4b0082,
            #ee82ee
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: sparkle 1.5s linear infinite;
}

@keyframes sparkle {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 100%;
    }
}
