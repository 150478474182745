
$base: 0.6rem;

.works_chevron_container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100%;
  height: 100vh;
  vertical-align: bottom;
  //border: 1px solid #ffffff;
  max-height: 90px;
  margin-top:10px;
  margin-bottom:5px;

}

.works_chevron {
  cursor: pointer;
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.works_chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}
.works_chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.works_chevron:before,
.works_chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #ffffff;
}

.works_chevron:before {
  left: 0;
  transform: skewY(30deg);

  border-left: .01em solid #96B6FF;
  border-bottom:  .5px solid #96B6FF;
  border-top: .5px solid #96B6FF;
  background:#6392FF;
}

.works_chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
  background:#6392FF;

  border-right: .01em solid #96B6FF;
  border-bottom:  .5px solid #96B6FF;
  border-top: .5px solid #96B6FF;
}

.about_chevron_container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100%;
  height: 100vh;
  vertical-align: bottom;
  //border: 1px solid #ffffff;
  max-height: 30px;
  margin-top:100px;
  margin-bottom:25px;

}

.about_chevron {
  cursor: pointer;
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.about_chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}
.about_chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.about_chevron:before,
.about_chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #ffffff;
}

.about_chevron:before {
  left: 0;
  transform: skewY(30deg);

  border-left: .01em solid #96B6FF;
  border-bottom:  .5px solid #96B6FF;
  border-top: .5px solid #96B6FF;
  background:#6392FF;
}

.about_chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
  background:#6392FF;

  border-right: .01em solid #96B6FF;
  border-bottom:  .5px solid #96B6FF;
  border-top: .5px solid #96B6FF;
}

.chevron_container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  vertical-align: bottom;
  //border: 1px solid #ffffff;
  max-height: 90px;

}
.chevron {
  cursor: pointer;
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}
.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #ffffff;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);

  border-left: .01em solid #96B6FF;
  border-bottom:  .5px solid #96B6FF;
  border-top: .5px solid #96B6FF;
  background:#6392FF;
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
  background:#6392FF;

  border-right: .01em solid #96B6FF;
  border-bottom:  .5px solid #96B6FF;
  border-top: .5px solid #96B6FF;
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY($base * 3.8);
  }
  66.6% {
    opacity: 1;
    transform: translateY($base * 5.2);
  }
  100% {
    opacity: 0;
    transform: translateY($base * 8) scale(0.5);
  }
}